import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import PropTypes from 'prop-types';
import { withStyles } from '@mui//styles';
import { MdFileUpload, MdClear } from 'react-icons/md';
import IconButton from "@mui/material/IconButton";
import { Storage } from 'aws-amplify';

// Local imports

import { PrimaryButton, SecondaryButton } from "../styled-components/Buttons/Buttons";
import { CardComponent2R1C } from '../layout/CardComponent';

import { saveFile } from "../../redux/actions/index";
import { getS3params } from './FileUploadApi';
const styles = {
    uploadContainer: {
        borderRadius: "4px",
        border: "dotted 2px var(--primary-background-color)",
        backgroundColor: "rgba(41, 97, 253, 0.05)",
        height: "199px",

    },
    uploadIcon: {
        width: "72px",
        height: "72px",
        opacity: "0.87",
    },
    text: {
        opacity: "0.87",
        fontSize: "20px",
        fontWeight: "500",
    },
}

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveFile: (payload, callback) => dispatch(saveFile(payload, callback)),
    }
};

class FileUpload extends React.Component {

    state = {
        hideUploadContainer: this.props.hideUploadContainer || false,
        uploadedFiles: [],
        accessKeyId: '',
        secretAccessKey: ''
    }

    constructor(props, context) {
        super(props, context);
        this.fileResCallback = this.fileResCallback.bind(this);
        this.deleteFile = this.deleteFile.bind(this);

    }


    /**
     * 
    */

    async onChange(e) {

        const file = e.target.files[0];

        let file_name = file.name;
        file_name = "" + new Date().getTime() + "__" + file_name;
        Storage.put(file_name, file,{
            contentType: file.type, 
            credentials: {
                accessKeyId: this.state.accessKeyId,
                secretAccessKey: this.state.secretAccessKey
            }
        })
        .then(this.handleSuccesfullUpload.bind(this, file))
        .catch(this.handleError.bind(this))

    }

    /**
     * 
    */

    handleSuccesfullUpload (file, data) {
        data.size = file.size + "";

        data.downloadTitle = file.name;
        this.props.saveFile(data, this.fileResCallback);

        Storage.get(data.key, {
            credentials: {
                accessKeyId: this.state.accessKeyId,
                secretAccessKey: this.state.secretAccessKey
            }
        })
        .then((result) => {
            
            var newData = {
                key: data.key,
                size: data.size,
                downloadLink: result
            }
            this.props.singleupload && this.setState({ hideUploadContainer: true })
            this.setState({ uploadedFiles: this.state.uploadedFiles.concat([newData]) })

        })
        .catch(err => console.log(err));
        // if(this.props.singleupload) {

        // }
    }

    /**
     * 
    */

    fileResCallback(data) {
        this.props.fileToSave && this.props.fileToSave(data, this.props.field);
    }

    handleError(err) {
        console.log("Error", err);
    }

    /**
     * 
    */

    uploadButtonClick(e) {
        this.uploadInput.click()
    }

    getProperKeyName(key) {
        if(key) {
            return key.substring(key.indexOf("__") + 2, key.length)
        }

        return key;
    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    /**
     * 
    */

    deleteFile(e, file) {
        this.state.uploadedFiles.forEach( (uploadedFile, index) => {
            if(file.key === uploadedFile.key) {
                this.state.uploadedFiles[index].deleted = true;
            }
        })

        if(this.props.singleupload) {
            this.setState({ uploadedFiles: [] });
            this.setState({ hideUploadContainer: false });
        } else {
            this.setState({ uploadedFiles: this.state.uploadedFiles });
        }


        this.props.fileToDelete && this.props.fileToDelete(file, this.props.field);
        e.preventDefault();
        e.stopPropagation();
    }

    removeDeletedFiles (files) {

        let arr = []

        files.forEach((file, index) => {
            if (!file.deleted) {
                arr.push(file)
            }
        })

        return arr;

    }

    async componentDidMount() {
        let s3params = await getS3params();
        this.setState({
            accessKeyId: s3params[0]
        });
        this.setState({
            secretAccessKey: s3params[1]
        });
        if(this.props.uploadedFile) {
            Storage.get(this.props.uploadedFile.title, {
                credentials: {
                    accessKeyId: s3params[0],
                    secretAccessKey: s3params[1]
                }
            })
            .then((result) => {
                var data = {
                    key: this.props.uploadedFile.title,
                    size: this.props.uploadedFile.size,
                    downloadLink: result
                }
                this.props.singleupload && this.setState({ hideUploadContainer: true })
                this.setState({ uploadedFiles: this.state.uploadedFiles.concat([data]) })

            })
            .catch(err => console.log(err));
        }

        if (this.props.extraFiles) {
            this.props.extraFiles.forEach ((file) => {

                Storage.get(file.title, {
                    credentials: {
                        accessKeyId: s3params[0],
                        secretAccessKey: s3params[1]
                    }
                })
                .then((result) => {
                    var data = {
                        key: file.title,
                        size: file.size,
                        downloadLink: result
                    }
                    // this.setState({ hideUploadContainer: true })
                    this.setState({ uploadedFiles: this.state.uploadedFiles.concat([data]) })

                })
                .catch(err => console.log(err));
            })
        }
    }

    render() {
        
        const { classes, allowStateToEdit } = this.props;
        const allowStateToEditBool = Boolean(allowStateToEdit);

        return (
            <div className={this.props.className}>
                {
                    this.props.notitle ? "" : <div className="layout horizontal section-title ">File Attachments</div>
                }
                

                <div className='layout horizontal wrap'>
                    {
                        !this.props.nouploadedcontainer && this.removeDeletedFiles(this.state.uploadedFiles).map((item) => {
                            return (
                                <div className="upload-card-margin" key={item.key}>
                                    <a target="_blank" title={this.getProperKeyName(item.key)}  href={item.downloadLink}>
                                        <CardComponent2R1C 
                                            className="set-width"
                                            title={<span className="truncate">{this.getProperKeyName(item.key)}</span>}
                                            title2={<span className="opacity-54">{this.bytesToSize(item.size)}</span>}
                                            suffix={

                                                this.props.noEdit ? "" :
                                                <IconButton
                                                    aria-label="Delete"
                                                    hidden={(!this.props.showCancelForState && !allowStateToEditBool && this.props.lempgUserType && this.props.lempgUserType !== "LEMPG_ACCESS")} 
                                                    aria-haspopup="true"
                                                    onClick={(e) => this.deleteFile(e, item)}
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
                
                <div className="height-10"></div>

                {
                    this.state.hideUploadContainer ? null : 
                    this.props.nocontainer ? (
                        <>
                            <input
                                ref={input => {
                                    this.uploadInput = input;
                                }}
                                type="file"
                                onChange={(e) => this.onChange(e)}
                            />
                            
                            {
                                this.props.primarybutton ? 
                                <PrimaryButton setwidth="true" onClick={this.uploadButtonClick.bind(this)}>
                                    Attach
                                </PrimaryButton>
                                :                                 
                                <SecondaryButton setwidth="true" onClick={this.uploadButtonClick.bind(this)}>
                                    Attach
                                </SecondaryButton>

                            }
                        </>
                    )
                    :
                    (

                        <div hidden={(this.props.lempgUserType && !allowStateToEditBool && this.props.lempgUserType !== "LEMPG_ACCESS")} className={`layout vertical center vertical-center full-height ${classes.uploadContainer}`}>
                            <input
                                ref={input => {
                                    this.uploadInput = input;
                                }}
                                type="file" 
                                onChange={(e) => this.onChange(e)}
                            />

                            <div className="height-10"></div>
                            <MdFileUpload className={`${classes.uploadIcon}`} />
                            <div className="height-10"></div>
                            <span className={`${classes.text}`}>{ this.props.singleupload ? "Upload file" : "Upload files"}</span>
                            <div className="height-10"></div>

                            <PrimaryButton onClick={this.uploadButtonClick.bind(this)} style={{ width: "256px" }}>
                                Add file
                             </PrimaryButton>

                        </div>                        

                    )
                }
                
            </div>
        )
    }

}

FileUpload.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FileUpload));
